import { Injectable } from '@angular/core';
// import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly position: string = 'br';

  // constructor(private message: MessageService) {}

  // success(title: string, message: string) {
  //   this.message.add({
  //     //key: this.position,
  //     severity: 'success',
  //     summary: title,
  //     detail: message,
  //   });
  // }

  // warning(title: string, message: string) {
  //   this.message.add({
  //     //key: this.position,
  //     severity: 'warn',
  //     summary: title,
  //     detail: message,
  //   });
  // }

  // info(title: string, message: string) {
  //   this.message.add({
  //     severity: 'info',
  //     summary: title,
  //     detail: message,
  //   });
  // }

  // error(title: string, message: string) {
  //   this.message.add({
  //     //key: this.position,
  //     severity: 'error',
  //     summary: title,
  //     detail: message,
  //   });
  // }
}
