import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EndPointService } from '@app/data/service/endpoint.service';
import { ToastController } from '@ionic/angular';


@Component({
  selector: 'app-withdrawsheet',
  templateUrl: './withdrawsheet.component.html',
  styleUrls: ['./withdrawsheet.component.scss'],
})
export class WithdrawsheetComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private readonly formBuilder: FormBuilder,
    private endpoint: EndPointService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    this.form = this.formBuilder.group({
      pmaccountnumber: ['',
        [
          Validators.required,
        ]
      ],
      amount: ['', [Validators.required, Validators.min(8)]],
    });
  }

  canWithdraw(){
    const payload = this.form.value; 

    this.endpoint.initWithdraw(payload.pmaccountnumber, payload.amount).subscribe({
      next: (data) => {
        console.table(data);
        this.presentToast('Your withdrawal request will be validate soon');
      },
      error: (err) => {
        this.presentToast('An error occured');
        console.log(err);
      },
      //complete: () => this.loading = false,
    });
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1500,
      position: 'bottom',
    });

    await toast.present();
  }

}
