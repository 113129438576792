import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtInterceptorProvider } from './interceptor/jwt.interceptor';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    JwtInterceptorProvider,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
})
export class CoreModule {}
