import { PaginationQueries } from "./utils/pagination-queries";

export const API_BASE_URL = 'https://apisol.marksolcorp.fr';

export const PM_URL = "https://perfectmoney.com/api/account.asp";

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

export const PM={
  PAYEE_ACC: 'U17207133',
  PAYEE_NAME:'SOLCORP',
  PAYMENT_AMOUNT: '', 
  PAYMENT_UNITS: '', 
  PAYMENT_URL: '', 
  STATUS_URL: '', 
  PAYMENT_MEMO: '',
  PAYMENT_URL_METHOD: '',
  NOPAYMENT_URL:'',
  // ee:'',
  // ede:'',
  // dd:''
};

const PAGINATION_TOTAL_ITEMS_PER_PAGE: number = 30;

const PAGINATION_QUERIES_DEFAULT: PaginationQueries = {
  itemsPerPage: PAGINATION_TOTAL_ITEMS_PER_PAGE,
  page: 1,
  pagination: true,
};


export {
    PAGINATION_QUERIES_DEFAULT,
    PAGINATION_TOTAL_ITEMS_PER_PAGE,
}