import { Injectable, inject } from '@angular/core';
import { User } from '@app/data/model/api/api-resources/user.model';
import { JWTDecoded, JWTToken } from '@app/data/model/auth/jwt-token.model';
import { jwtDecode } from "jwt-decode";

import { LocaleStorageService } from './locale-storage.service';


@Injectable({
  providedIn: 'root',
})
export class JWTTokenService {
  public static readonly JWT_TOKEN_KEY: string = 'token';
  public static readonly JWT_REFRESH_TOKEN_KEY: string = 'refresh_token';
  public static readonly CURRENT_USER: string = 'current_user';
  
  constructor(private ls: LocaleStorageService) {}

  saveJwtData(jwt: JWTToken) {
    this.saveToken(jwt.token);
    this.saveRefreshToken(jwt.refresh_token);
  }

  /**
   * Save  `token`  in locale storage
   * @param token string
   * @return void
   */
  saveToken(token: string): void {
    this.ls.set(JWTTokenService.JWT_TOKEN_KEY, token);
    //localStorage.setItem(JWTTokenService.JWT_REFRESH_TOKEN_KEY, jwt.refresh_token);
  }

  saveUser(currentUser: User) {
    //currentUser = { ...currentUser, '@id': `/api/users/${currentUser.id}` };
    this.ls.set(JWTTokenService.CURRENT_USER, currentUser, true);
  }

  getUser(): User | null {
    return this.ls.get(JWTTokenService.CURRENT_USER, true);
  }

  

  

  getUsersIRis(): string {
    const user = this.getUser();
    if (user) {
      return `/api/users/${user.id}`;
    }
    return '/api/users/unknow';
  }

  isSeller(): boolean {
    const user = this.getUser();
    if (user) {
      return user.roles.includes('ROLE_VENDEUR');
    }
    return false;
  }

  
  /**
   * returns jwt token from locale storage if exist
   * @returns string | null
   */
  getToken(): string | null {
    return this.ls.get(JWTTokenService.JWT_TOKEN_KEY);
  }

  /**
   * returns jwt refresh_token from locale storage if exist
   * @returns string | null
   */
  getRefreshToken(): string | null {
    return this.ls.get(JWTTokenService.JWT_REFRESH_TOKEN_KEY);
  }

  /**
   * save jwt refresh_token in locale storage
   */
  saveRefreshToken(refreshToken: string) {
    this.ls.set(JWTTokenService.JWT_REFRESH_TOKEN_KEY, refreshToken);
  }

  clear() {
    this.ls.clear();
  }

  /**
   * extract jwt token expire date from token
   */
  private decodeToken(token: string): JWTDecoded | null {
    let decoded = jwtDecode<JWTDecoded>(token);
    if (decoded) {
      if (decoded.exp) {
        const { exp } = decoded;
        const expireAt = new Date(Number(exp) * 1000);
      }
      return decoded;
    }
    return null;
  }

  getExpiryTime() {
    const decodedToken = this.decodeToken(
      this.ls.get(JWTTokenService.JWT_TOKEN_KEY)
    );
    return decodedToken ? decodedToken.exp : null;
  }

  /**
   * Check if jwt token as expired
   * @return boolean
   */
  isTokenExpired(): boolean {
    const expiryTime = this.getExpiryTime();
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }

  /**
   * check if user is authenticated
   * @return boolean
   */
  isLogged(): boolean {
    const token = this.getToken();
    if (!!token && !this.isTokenExpired()) {
      return true;
    }
    return false;
  }
  userIsLogged(): boolean {
    return !!this.getUser();
  }
  
}
