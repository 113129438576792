import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // // Save data to local storage
  // set(key: string, value: any): void {
  //   localStorage.setItem(key, JSON.stringify(value));
  // }

  // // Retrieve data from local storage
  // get(key: string): any {
  //   const storedValue = localStorage.getItem(key);
  //   return storedValue ? JSON.parse(storedValue) : null;
  // }

  // Save data to local storage
  set(key: string, value: any): void {
    // For JWTs or strings, store as-is; for objects, stringify.
    const storedValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, storedValue);
  }

  // Retrieve data from local storage
  get(key: string): any {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue ? JSON.parse(storedValue) : null; // Parse if it's JSON
    } catch (e) {
      return storedValue; // If parsing fails, return as is (likely a string)
    }
  }

  // Remove data from local storage
  remove(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all data from local storage
  clear(): void {
    localStorage.clear();
  }

  // Check if all required items are present
  checkRequiredItems(): boolean {
    const requiredItems = ['token', 'refresh_token', 'currentUser'];
    for (const item of requiredItems) {
      if (!this.get(item)) {
        return false;
      }
    }
    return true;
  }
}
