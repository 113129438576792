import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, retry, switchMap, take } from 'rxjs/operators';
import { API_BASE_URL, InterceptorSkipHeader } from '../constants';
import { JWTTokenService } from '../service/jwt.service';

import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { JWTToken } from '@data/model/auth/jwt-token.model';
import { ToastService } from '@shared/service/toast.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly TOKEN_HEADER_KEY: string = 'Authorization';
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private jwtService: JWTTokenService,
    private authService: AuthService,
    private toast: ToastService,
    private router: Router
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const jwtToken = this.jwtService.getToken();
    console.log(request);
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers:headers }));
    }
    if (!!jwtToken) {
      let cloneRequest = this.addTokenHeader(request, jwtToken);
      return next.handle(cloneRequest).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !cloneRequest.url.includes('/api/login') &&
            error.status === 401
          ) {
            return this.handle401Error(cloneRequest, next);
          }
          return this.handleError(error);
        })
      );
    }
    let cloneRequest = request.clone({
      url: `${API_BASE_URL}${request.url}`,
    });
    return next.handle(cloneRequest).pipe(catchError(this.handleError));
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string,
    needPrefix: boolean = true
  ) {
    if (needPrefix) {
      return request.clone({
        headers: request.headers.set(this.TOKEN_HEADER_KEY, 'Bearer ' + token),
        url: `${API_BASE_URL}${request.url}`,
      });
    }
    return request.clone({
      headers: request.headers.set(this.TOKEN_HEADER_KEY, 'Bearer ' + token),
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.jwtService.getRefreshToken();
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((jwt: JWTToken) => {
            this.isRefreshing = false;
            this.jwtService.saveToken(jwt.token);
            this.refreshTokenSubject.next(jwt.refresh_token);
            return next.handle(this.addTokenHeader(request, jwt.token, false));
          }),
          retry(1),
          catchError((err) => {
            console.log('REFRESH_TOKEN FAILURE ERROR', err);
            this.isRefreshing = false;
            this.authService.logout();
            //  this.toast.warning(
            //    'Authentication failure',
            //    'Access refusé ou votre session à expirer. Veillez-vous reconnecter svp!'
            //  );
            this.router.navigate(['/auth/login']);
            return throwError(err);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log('HTTP CODE', error.status, error.statusText);
    let err: any = error;
    if (error.error instanceof ErrorEvent) {
      err = error.error.message;
    } else {
      err = error.error;
      if (error.status === 0) {
        const errMsg: string =
          "Une erreur côté client ou réseau s'est produite.Veillez vérifier votre connexion internet.";
        err = {
          status: 0,
          message: errMsg,
        };
        //this.toast.warning('Réseau', errMsg);
      } else if (error.status == 401) {
        err = { status: 401, message: error.error.message }; //`Access denied. You are not logged in.`;
      } else if (error.status == 403) {
      } else if (error.status == 404) {
        err = { status: 404, message: error.error['hydra:description'] };
      } else if (error.status >= 500 && error.status < 600) {
        err = error.error;
      }
    }
    return throwError(err);
  }
} 

export const JwtInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};
