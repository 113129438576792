import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleStorageService {
  constructor() {}

  set(key: string, value: any, isStringifyable: boolean = false) {
    if (isStringifyable) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  }

  get(key: string, isParseable: boolean = false) {
    if (isParseable) {
      const value = localStorage.getItem(key);
      return value? JSON.parse(value): null;
    }
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
