import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonButton } from '@ionic/angular';
import { WithdrawsheetComponent } from './components/withdrawsheet/withdrawsheet.component';
import { NgxWheelTemplateComponent } from '../pages/tabs/ngxwheeltemplate/ngxwheeltemplate.component';
// import { ToastModule } from 'primeng/toast';
// import { ConfirmationService, MessageService } from 'primeng/api';
// import { AccordionModule } from 'primeng/accordion';
// import { DialogModule } from 'primeng/dialog';
// import { SkeletonModule } from 'primeng/skeleton';
// import { TabViewModule } from 'primeng/tabview';
// import { TabMenuModule } from 'primeng/tabmenu';
// import { ButtonModule } from 'primeng/button';
// import { ImageModule } from 'primeng/image';
// import { BadgeModule } from 'primeng/badge';
// import { EditorModule } from 'primeng/editor';
// import { MessagesModule } from 'primeng/messages';
// import { TableModule } from 'primeng/table';
// import { TagModule } from 'primeng/tag';
// import { SimpleLoaderComponent } from './components/simple-loader/simple-loader.component';
// import { SpeedDialModule } from 'primeng/speeddial';
// import { TooltipModule } from 'primeng/tooltip';
// import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
  declarations: [
    // CountUpDirective,
    // SimpleLoaderComponent,
    WithdrawsheetComponent,
    
  ],
  imports: [CommonModule,
    // DialogModule,    
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule, 
    // ToastModule, 
    CommonModule, 
    //NgxWheelTemplateComponent,
    // AccordionModule,
    // SimpleLoaderComponent,
    // DialogModule,
    // CountUpDirective,
    // SkeletonModule,
    // TabViewModule,
    // TabMenuModule,
    // ButtonModule,
    // ImageModule, 
    // BadgeModule,
    // EditorModule,
    // MessagesModule,
    // TableModule,
    // TagModule,
    // SpeedDialModule,
    // TooltipModule,
    // ConfirmDialogModule
  ],
  // providers: [MessageService, ConfirmationService],
})
export class SharedModule {}
