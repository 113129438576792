import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JWTTokenService } from '@app/core/service/jwt.service';
import { PaginationQueries } from '@app/core/constants/utils/pagination-queries';
import { InterceptorSkipHeader, PAGINATION_QUERIES_DEFAULT, PM, PM_URL } from '@app/core/constants';
import { Compte, Pack, Transaction } from '../model/api/api-resources/user.model';

@Injectable({
  providedIn: 'root',
})
export class EndPointService {
  baseUrl: string = '/api/subscriptions';
  constructor(
    private httpClient: HttpClient,
    private jwtService: JWTTokenService
  ) {}



  /*================PACKS=================*/
      getAllPacks(
        queries: PaginationQueries = PAGINATION_QUERIES_DEFAULT
      ){
        const user = this.jwtService.getUser();
        let payload = {
          user_code: user?.user_code,
        };
        let endpoints = '/api/get-all-pack';
        return this.httpClient.post<any>(endpoints, payload);
      }

      subscribeUserPack(pack : string|number
      ){
        const user = this.jwtService.getUser();
        let payload = {
          user_code : user?.user_code,
          pack_id: pack
        };
        console.log(payload);
        // user_code pack_id
        let endpoints = '/api/subscribe-to-pack';
        return this.httpClient.post<any>(endpoints, payload);
      }
  /*==============END PACKS==================*/

  /*================USER ACCOUNT=================*/
      getUsrAccountDetails(
      ) {
        const user = this.jwtService.getUser();
        let user_code =  {
          user_code : user?.user_code
        };
        let endpoints = '/api/get-user_compte';        
        return this.httpClient.post<any>(endpoints, user_code);
      }
      
  /*==============END USER ACCOUNT==================*/

  /*================USER AFFILIATE=================*/
    getUsrAffiliates(
    ) {
      const user = this.jwtService.getUser();
      let user_code = {
        user_code: user?.user_code
      };
      let endpoints = '/api/get-user-affiliate';
      return this.httpClient.post<any>(endpoints, user_code);
    }


    getUsersPacksscs(
      queries: PaginationQueries = PAGINATION_QUERIES_DEFAULT
    ) {
      let endpoints = '/api/get-user_affiliatee';
      return this.httpClient.get<Pack>(endpoints);
    }
    
  /*==============END USER AFFILIATES==================*/

  /*================TASKS=================*/
    getAllTask(
      queries: PaginationQueries = PAGINATION_QUERIES_DEFAULT
    ) {
      let endpoints = '/api/get-all-tasks';
      return this.httpClient.get<Pack>(endpoints);
    }

    getUserTasks(
    ) {
      const user = this.jwtService.getUser();
      let payload = {
        user_code: user?.user_code
      };
      let endpoints = '/api/get-user-tasks';
      return this.httpClient.post<any>(endpoints,payload);
    }

    closeTask(task: string | number
    ) {
      const user = this.jwtService.getUser();
      let payload = {
        user_code: user?.user_code,
        task_id: task
      };
      let endpoints = '/api/closing-task';
      return this.httpClient.post<any>(endpoints, payload);
    }
  /*==============END TASKS==================*/

  /*================TASKS=================*/
    getUserTransactions(
      queries: PaginationQueries = PAGINATION_QUERIES_DEFAULT
    ) {
      const user = this.jwtService.getUser();
      let user_code = {
        user_code: user?.user_code
      };
      let endpoints = '/api/get_list_transaction';
      return this.httpClient.post<any>(endpoints, user_code);
    }

    getUserWithdrawalRequest(
      queries: PaginationQueries = PAGINATION_QUERIES_DEFAULT
    ) {
      const user = this.jwtService.getUser();
      let user_code = {
        user_code: user?.user_code
      };
      let endpoints = '/api/get-list-withdraw-request';
      return this.httpClient.post<any>(endpoints, user_code);
    }
    
    initWithdraw(
      pmacc:string, amount:number
    ) {
      const user = this.jwtService.getUser();
      let user_code = {
        user_code: user?.user_code,
        pmaccountnumber: pmacc,
        amount:amount
      };
      let endpoints = '/api/launch_withdraw';
      return this.httpClient.post<any>(endpoints, user_code);
    }

    // getUserTasks(
    // ) {
    //   const user = this.jwtService.getUser();
    //   let user_code = user?.user_code as {};
    //   let endpoints = '/api/get-user-tasks';
    //   return this.httpClient.get<any>(endpoints, user_code);
    // }

  pmDeposit(fdd: FormData, bypassInterceptor: boolean = false
    ) {
      // let data :{} = {
      //   'PAYEE_ACCOUNT': PM.PAYEE_ACC,
      //   'PAYEE_NAME': PM.PAYEE_NAME,
      //   'PAYMENT_AMOUNT': amount,
      //   'PAYMENT_UNITS': 'USD',
      //   'PAYMENT_ID': '',
      //   'PAYMENT_URL': 'URL_DE_VOTRE_SITE/paiement_retour/',   
      //   'STATUS_URL': 'URL_DE_VOTRE_SITE/paiement_statut/',  
      //   'PAYMENT_MEMO': 'memo',
      // };
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
      return this.httpClient.post<any>(PM_URL, fdd, {headers:headers});
    }
  /*==============END TASKS==================*/
}
// api/apps_user/{user_id}