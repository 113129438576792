import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { noAuthGuard } from './core/guard/no-auth.guard';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'mainview',
    pathMatch: 'full'
  },
  {
    path: 'mainview',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule), 
    canActivate: [noAuthGuard],
  },
  // {
  //   path: 'main',
  //   loadChildren: () =>
  //     import('./modules/public/public.module').then((m) => m.PublicModule),
  //   // canActivate: [noAuthGuard],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
