import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { JWTToken } from '@app/data/model/auth/jwt-token.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { JWTTokenService } from './jwt.service';
import { User } from '@data/model/api/api-resources/user.model';
import { Credentials } from '@app/data/model/auth/credentials.model';




@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static readonly AUTHENTICATED_USER_KEY = 'current_user';
  public readonly baseUrl = '/api/auth';
  
  private _logout$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(
    private httpClient: HttpClient,
    private jwtService: JWTTokenService,
   
  ) {}

  login(creadentials: Credentials): Observable<any> {
    return this.httpClient.post<any>(`/api/login`, creadentials);
  }
  signup(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`/api/signup`, formData);
  }
  checkUserPassword(creadentials: Credentials): Observable<any> {
    return this.httpClient.post<any>(`/api/check-password`, creadentials);
  }

  forgotPasword(identifier: { email: string }): Observable<any> {
    return this.httpClient.post<any>('/api/password/reset', identifier);
  }

  OtpVerifyAccount(data: { email: string; code: string }): Observable<any> {
    return this.httpClient.post(`/api/users/verify`, data);
  }

  doubleFactorChannel(channel: 'sms' | 'verify'): Observable<any> {
    return this.httpClient.post('/api/users/double/factor', {
      channel: channel,
    });
  }

  doubleFactorVerify(code: string): Observable<any> {
    return this.httpClient.post('/api/users/double/factor/verify', { code });
  }

  logout() {
    
    this.setLogout$(false);
  }

  getLogout$(): BehaviorSubject<boolean> {
    return this._logout$;
  }
  setLogout$(isLogout: boolean) {
    this._logout$.next(isLogout);
  }

  getMe(): Observable<User> {
    return this.httpClient.get<User>(`/api/me`);
  }

  refreshToken(refresh_token: string): Observable<JWTToken> {
    return this.httpClient.post<JWTToken>(`/token/refresh`, {
      refresh_token: refresh_token,
    });
  }
  


}
