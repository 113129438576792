// import { CanActivateFn } from '@angular/router';

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JWTTokenService } from '../service/jwt.service';
import { LocalStorageService } from '@app/shared/service/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private jwtTokenService: JWTTokenService, private router: Router,private localStorageService: LocalStorageService,) {}

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
  //   const token = this.jwtTokenService.getToken();
  //   const refreshToken = this.localStorageService.get('refresh_token');
  //   const currentUser = this.localStorageService.get('currentUser');

  //   if (!!token && !!refreshToken && !!currentUser) {
  //     // User is authenticated
  //     console.log('dddd')
  //     this.router.navigate(['/mainview/home']); 
  //     return true;
  //   } else {
  //     this.router.navigate(['/auth/login']); 
  //     return false;
  //   }
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isLogged = this.jwtTokenService.isLogged();
    const user = this.jwtTokenService.getUser();
    if (user) {
      return true;
    }else{
      // this.toast.warning(
      //   'Connexion',
      //   'Veuillez-vous connecter avant de poursuivre'
      // );
      this.router.navigate(['/']);
      //this.authService.showLoginModal$.next(true);
    }
    
    return !!user;
  }
}
